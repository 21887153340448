// ? import GSAP
// needed for on slide change animation
import { gsap } from 'gsap';
// ? import Swiper JS
// import Swiper, { Autoplay, Navigation, EffectCoverflow, EffectFade, FreeMode, Thumbs } from 'swiper';
import Swiper, { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
// ? import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/free-mode';
// import 'swiper/css/thumbs';

let _homepage = document.body.classList.contains('home');
let _product = document.body.classList.contains('page_product');

// ! SLIDERS
const __fullSizeSlider = '.full-size__slider';
// * HOME
const _home_marketing = '.tiles-marketing';
const _home_magazines = '.tiles-magazines';
const _home_newsletters = '.tiles-newsletters';
const _home_bundles = '.tiles-bundles';

// * PRODUCT
const _product_related_products = ".related--products";

// ! SLIDER TYPES
// SIMPLE SLIDER SETTINGS
const fullPageFadeSettings = {
    modules: [Autoplay, Pagination, EffectFade],
    speed: 1000,
    spaceBetween: 0,
    effect: 'fade',
    slidesPerView: 1,
    fadeEffect: {
        crossFade: true,
    },
    on: {
        init: function () {
            // console.log('swiper initialized');
            childrenFadeEffect('.js-fade-slide__children');
        },
    },
};

// TILE SLIDER SETTINGS
const tileSettings = {
    modules: [Pagination, Navigation],
    speed: 1000,
    spaceBetween: 0,
    slidesPerView: 'auto',
};

// TILE SLIDER SETTINGS
const tileRelatedProductsSettings = {
    modules: [Pagination, Navigation],
    spaceBetween: 0,
    // slidesPerView: 'auto',
};


window.addEventListener('DOMContentLoaded', () => {

    if (_homepage) {
        // if (document.querySelector(__fullSizeSlider + '__slider')) {
            // simpleFullSlider(__fullSizeSlider, fullPageFadeSettings);
        // }
        // if (window.innerWidth < 768) {
            if (document.querySelector(_home_marketing + '__slider')) {
                tileSlider(_home_marketing, tileSettings);
            }
            if (document.querySelector(_home_magazines + '__slider')) {
                tileSlider(_home_magazines, tileSettings);
            }
            if (document.querySelector(_home_newsletters + '__slider')) {
                tileSlider(_home_newsletters, tileSettings);
            }
            if (document.querySelector(_home_bundles + '__slider')) {
                tileSlider(_home_bundles, tileSettings);
            }
        // }
    }

    if (_product) {

        $.ajax({
            url: 'index.php?route=product/product/fetchProductEvents',
            type: 'POST',
            data: {
                product_id: $('#product-id-input').val(),
            },
            dataType: 'html',
            success: function (html) {
                $('#event-boussias').append(html)

                if($('.relatedEvents').length){
                    const eventsSwiper = new Swiper(".relatedEvents", {
                        modules: [Navigation],
                        slidesPerView: 1,
                        spaceBetween: 50,
                        freeMode: true,
                        breakpoints: {
                            581: {
                                slidesPerView: 2,
                                spaceBetween: 15
                            },
                            991: {
                                slidesPerView: 3,
                                spaceBetween: 55
                            },
                            1280: {
                                slidesPerView: 3,
                                spaceBetween: 90
                            },
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 125
                            },

                        },
                        navigation: {
                            nextEl: ".related-events-arrow.next",
                            prevEl: ".related-events-arrow.prev",
                        },
                    });
                }

            }
        });




        if(window.innerWidth < 581){
            tileSlider(_product_related_products, tileRelatedProductsSettings)
        }
    }


    //only cases that are sensitive from ajax requests
    // $(document).ajaxStop(function () {
    //
    // });
});

let preStractureSlider = (sw) => {
    var swContainer = document.querySelectorAll(sw + '__slider');
    var swWrapper = document.querySelectorAll(sw + '__sliderwrap');
    var swSlides = document.querySelectorAll(sw + '__item');

    if (swContainer && swWrapper && swSlides) {
        swContainer.forEach(function (swC) {
            swC.classList.add('swiper');
            // setTimeout(function(){
            //     swC.parentElement.classList.add('js-show');
            // }, 1000);
            swWrapper.forEach(function (swW) {
                swW.classList.add('swiper-wrapper');
                for (var i = 0; i < swSlides.length; i++) {
                    swSlides[i].classList.add('swiper-slide');
                }
            });
        });
    }
};

let simpleFullSlider = (sw, settings) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = settings;
    var item = document.querySelectorAll(sw + '__item');
    var itemLength = item.length;
    sliderSettings.loop = itemLength > sliderSettings.slidesPerView;
    // sliderSettings.navigation = {
    //     prevEl: sw + '__item-prev',
    //     nextEl: sw + '__item-next',
    // };
    sliderSettings.pagination = {
        el: sw + '__pagination',
        type: 'bullets',
        clickable: true,
    };
    slider = new Swiper(sw + '__slider', sliderSettings);
    // console.log(slider);
    let thisSliderWrap = document.querySelector(sw + '__sliderwrap');
    thisSliderWrap.querySelector('.swiper-slide-active').classList.add('onPoint');
    slider.on('slideChange', function () {
        // console.log('slide changed');
        if(thisSliderWrap.querySelector('.swiper-slide-active')){
            thisSliderWrap.querySelector('.swiper-slide-active').classList.remove('onPoint');
        }
        childrenFadeEffect('.js-fade-slide__children');
    });
    slider.on('slideChangeTransitionEnd', function () {
        // console.log('slide changed ended');
        if(thisSliderWrap.querySelector('.swiper-slide-active')){
            thisSliderWrap.querySelector('.swiper-slide-active').classList.add('onPoint');
        }
    });

}; //OK

let tileSlider = (sw, settings) => {
    preStractureSlider(sw);
    var slider = {};
    var sliderSettings = settings;
    sliderSettings.pagination = {
        el: sw + '__pagination',
        type: 'bullets',
        clickable: true,
    };
    sliderSettings.navigation = {
        prevEl: sw + '__item-prev',
        nextEl: sw + '__item-next',
    };
    slider = new Swiper(sw + '__slider', sliderSettings);
}; //OK