import $ from "jquery";
import CheckoutStep from "./CheckoutStep";

export default class CheckoutPopup {

    constructor() {
        this.build();
        this.render();
    }

    build() {
        let html = '' +
                '<div class="checkout">' +
                    '<ul class="checkout__roadmap"></ul>' +
                    '<div class="checkout__steps"><div class="checkout__step"></div></div>' +
                '</div>';

        this.$content = $(html);
    }

    /**
     *
     * @param {CheckoutStep} step
     */
    addStep(step) {
        this.$content.find('.checkout__roadmap').append(step.$label);
        step.$label = this.$content.find(`.checkout__roadmap li[data-id="${step.id}"]`);
    }

    setContent(content) {
        this.$content.find('.checkout__step').html($(content));
        $(document).trigger('checkout-step:update-content', [this.$content.find('.checkout__step')]);
    }

    render() {
        this.$content.appendTo('#checkout-page');
    }
}