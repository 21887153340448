import $ from 'jquery';
import Repeater from "./Repeater";

export default class RepeaterRow {

    /**
     *
     * @param repeater {Repeater}
     * @param row {HTMLElement|null}
     */
    constructor(repeater, row = null) {

        if (!(repeater instanceof Repeater)) {
            throw new Error(`repeater argument expected to be type of Repeater, ${typeof (repeater)} given!`)
        }

        this.repeater = repeater;
        if (row === null) {
            let $html = $(this.repeater.$template.clone()
                .html()
                .replace(
                    / name="(.*?)"/gi,
                    ` name="${this.repeater.controller}[${this.repeater.currentIndex}]$1"`)
                .replace(
                    / id="(.*?)"/gi,
                    ` id="${this.repeater.controller}_$1_${this.repeater.currentIndex}"`)
                .replace(
                    / for="(.*?)"/gi,
                    ` for="${this.repeater.controller}_$1_${this.repeater.currentIndex}"`));

            // todo: could the code bellow be written in regex ?
            const _this = this;

            $html.find('[data-depend]').each(function () {
                let ors = [];
                let split_ors = $(this).data('depend');
                split_ors = split_ors.split('|').map((v) => v.trim());
                split_ors.forEach((d) => {
                    let split_ands = d.split(',').map((v) => v.trim());
                    let ands = [];
                    split_ands.forEach((a) => {
                        let [name, value] = a.split(":");
                        ands.push(`${_this.repeater.controller}[${_this.repeater.currentIndex}]${name}:${value}`);
                    })
                    ors.push(ands);
                });

                let amended = [];
                ors.map((or) => {
                    amended.push(or.join(","))
                })
                $(this).attr('data-depend', amended.join("|"));
            })

            this.$el = $html.clone();


        } else {
            this.$el = $(row);
        }

        this.init();
    }

    init() {
        this.$button = $(`<button type="button" class="remove-row" title="${this.repeater.rmv_btn_text}"></button>`)
        this.$button.click('click', () => {
            this.remove();
        });

        this.$button.appendTo(this.$el);

        $(document).trigger('repeater-row:init', [this])
    }

    remove() {
        this.repeater.rows.splice(this.repeater.rows.indexOf(this), 1);
        this.$el.remove();
        this.repeater.update();
    }
}