$(() => {

    $('.js-select2').each( function(){ // ! DO NOT DELETE | ACCOUNT PAGES REQUIREMENT
        $(this).select2({
            minimumResultsForSearch: -1,
            width: 'style',
        });
    });
    
    $('.js-select2-custom').each( function(){ // ! DO NOT DELETE | ACCOUNT PAGES REQUIREMENT
        $(this).select2({
            minimumResultsForSearch: -1,
            width: 'style',
            dropdownCssClass: "js-select2-custom-dropdown"
        });
        $(this).siblings().addClass("js-select2-custom");
    });
});