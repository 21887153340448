
export default class StepsArray extends Array {
    constructor() {
        super();
        this.curr = 0;
    }
    prev() {
        if(this[--this.curr] === undefined) {
            ++this.curr;
            return false;
        }
        return this[this.curr];
    }
    next() {
        if(this[++this.curr] === undefined) {
            --this.curr;
            return false;
        }
        return this[this.curr];
    }
    current() {
        return this[this.curr];
    }
    seekTo(index) {
        if(this[index] === undefined) {
            return false;
        }
        this.curr = index;
        return this[this.curr];
    }
}