window.addEventListener('DOMContentLoaded', () => {
    dataTabs('kTabs'); // Add Class Without The Dot (.)
    kTabVisibility();
});

let kTabVisibility = () => {
    let triggerA = document.querySelector('.filters-industries');
    let triggerB = document.querySelector('.close--filters');
    let kTabWrap = document.querySelector('.kTabs__wrap');
    let underlay = document.querySelector('.close-filters');

    if (triggerA || triggerB || underlay) {
        [triggerA, triggerB, underlay].forEach((trigger) => {
            trigger.addEventListener('click', () => {
                kTabWrap.classList.toggle('non-visible');
                document.body.classList.toggle('kTabs-visible');
                underlay.classList.toggle('active');
            });
        });
    }
};

let dataTabs = (rootClass) => {
    // DOM STRACTURE
    let the_root_element = rootClass;
    const the_tab_placeholder = the_root_element + '__placeholder';
    const the_triggers_wrap = the_root_element + '__trigger--wrap';
    const the_trigger_item = the_root_element + '__trigger--item';
    const the_content_item = the_root_element + '__content--item';
    // ^ Content Items Require Wrapping | Any Wrap Would Do (e.g.: div,ul, e.t.c.) ^

    // Styling Classes
    const triggerStylingCurrentClass = the_trigger_item + '--current';
    const triggerWrapStylingClass = the_root_element + '__dropdown';

    // Attribute Fetching
    const dataTargetName = 'data-name';
    const dataTrigger = 'data-trigger';
    const dataTab = 'data-group';

    // Get The Root Element
    let dataTabRootEl = document.querySelector('.' + the_root_element);
    // Get The PlaceHolder
    let tabPlaceHolder = document.querySelector('.' + the_tab_placeholder);
    // Get The Triggers Wrap
    let dataTabTriggersWrap = document.querySelector('.' + the_triggers_wrap);
    // Get All Triggers & Get First Trigger
    let allDataTabTriggers = document.querySelectorAll('.' + the_trigger_item);
    let dataTabTriggerFirst = document.querySelector('.' + the_trigger_item);
    // Get First Content
    let dataTabContentFirst = document.querySelector('.' + the_content_item);

    // Handle Mobile Toggling Via Root Element
    if (dataTabRootEl && tabPlaceHolder) {
        window.addEventListener('resize', () => {
            dataTabTriggersWrap.classList.remove(triggerWrapStylingClass);
            tabPlaceHolder.classList.remove(triggerWrapStylingClass);
        });

        dataTabRootEl.addEventListener('click', () => {
            dataTabTriggersWrap.classList.toggle(triggerWrapStylingClass);
            tabPlaceHolder.classList.toggle(triggerWrapStylingClass);
        });
    }

    if (dataTabTriggerFirst) {
        // Set First Tab Trigger As Current / Add Styling Class .js-tab-trigger-current
        dataTabTriggerFirst.classList.add(triggerStylingCurrentClass);

        // Display Current Tab Trigger In Separate div.kTabs__placeholder | Use Case: Mobile
        tabPlaceHolder.textContent = dataTabTriggerFirst.getAttribute(dataTargetName);

        allDataTabTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                // Handle your Triggers & Placeholder Text Replacement
                let allTriggersSiblings = Array.prototype.slice.call(dataTabTriggerFirst.parentElement.children);
                allTriggersSiblings.forEach((sibling) => {
                    sibling.classList.remove(triggerStylingCurrentClass);
                    tabPlaceHolder.textContent = trigger.getAttribute(dataTargetName);
                });
                trigger.classList.add(triggerStylingCurrentClass);

                if (window.innerWidth <= 580) {
                    let second_level_menu = document.querySelector('.kTabs__side-b');
                    let second_level_menu_close = document.querySelector('.second-level--handle');
                    if (second_level_menu) {
                        second_level_menu.classList.add('show-second-level');
                        second_level_menu_close.classList.add('active');

                        if (second_level_menu_close) {
                            second_level_menu_close.addEventListener('click', () => {
                                second_level_menu.classList.remove('show-second-level');
                                second_level_menu_close.classList.remove('active');
                            });
                        }
                    }
                }

                // Handle your Groups
                let target = trigger.getAttribute(dataTrigger);
                let targetGroup = document.querySelectorAll('[' + dataTab + '="' + target + '"]');
                let allGroupsSiblings = Array.prototype.slice.call(dataTabContentFirst.parentElement.children);

                if (dataTabContentFirst) {
                    allGroupsSiblings.forEach((sibling) => {
                        sibling.style.display = 'none';

                        if (target == 'All') {
                            sibling.style.display = 'block';
                        }
                    });

                    if (targetGroup.length !== 0) {
                        targetGroup.forEach((group) => {
                            group.style.display = 'block';
                        });
                    }
                }
            });
        });
    }
};
