import $ from 'jquery';

export default class DependedElement {

    /**
     *
     * @param $scope The jQuery element the class will search in for references
     * @param {string} depends Comma(,) seperated input names paired with values, separated by colon(:). (use comma for and, use | for or)
     * @param $el The jQuery element that is controlled
     * @example new DependedElement($element, 'input_name:0, input_name2:1, input_name3:black').
     * For checkboxes we use 0,1 values, for all other input types their value.
     */
    constructor($scope, depends, $el) {
        this.$scope = $scope;
        this.$el = $el;

        this.$el.get(0).DependedElement = [...this.$el.get(0).DependedElement||[], ...[this]]
        this.groups = [];
        this.controllers = [];

        const ors = depends.split("|").map((v) => v.trim());

        ors.forEach((or) => {
            this.groups.push(or.split(",").map((v) => v.trim()));
        })

        this.init();
    }

    init() {
        let _this = this;

        this.groups.forEach((or, i) => {
            this.controllers.push([]);
            or.forEach((and) => {
                let [name, value] = and.split(':').map((v) => v.trim());
                this.controllers[i].push(new Controller(this.$scope.find(`[name="${name}"]`), value));

                // Change event
                this.$scope.find(`[name="${name}"]`).change(function() {
                    _this.check() ? _this.$el.slideDown() : _this.$el.slideUp()
                });
            })
        });

        // The initial state
        !this.check() && this.$el.hide();
    }

    check() {

        // all instances need to pass
        let passArray = [];

        for (const instance of this.$el.get(0).DependedElement) {
            for (const or of instance.controllers) {
                let pass = or.filter(controller => controller.check()).length === or.length;
                passArray.push(pass)
            }
        }

        return passArray.includes(true)
    }
}

class Controller {
    constructor($input, value) {
        this.$input = $input;
        this.value = value;
    }

    check() {
        switch (this.getType()) {
            case 'checkbox':
                return this.$input.prop('checked') === !!parseInt(this.value);
            case 'radio':
                return this.$input.filter(':checked').val() === this.value;
            default:
                return this.$input.val() === this.value;
        }
    }
    getType() {
        try {
            return this.$input[0].tagName === "INPUT"
                ? this.$input[0].type.toLowerCase()
                : this.$input[0].tagName.toLowerCase();
        } catch (error) {
            console.warn('Dependency selector "' + this.$input.selector +  '" not found in DOM! DependedElement may not work properly.');
        }
    }
}