import './checkout_page'

const tabItems = document.querySelectorAll(".tab__content--item");

if (tabItems) {
    tabItems.forEach(tab => {
        tab.addEventListener("click", function(event) {
            event.preventDefault();
            tabItems.forEach(allItems => {
                allItems.classList.remove('active')
            })
            tab.classList.add('active')
        })
    })
}