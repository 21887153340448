$(document).ready(function () {
    if ($(window).width() <= 991) {
        $('.footer--title.extend').on('click', function () {
            if (!$(this).parent().hasClass('_current')) {
                $('.footer--title').each(function (index, el) {
                    $(el).parent().removeClass('_open _current');
                });
            }
            $(this).parent().toggleClass('_open');
            $(this).parent().addClass('_current');
        });

        $('.title-submenu').on('click', function (e) {
            e.preventDefault();
            if (!$(this).parent().hasClass('_current')) {
                $('.subcategory .title-submenu').each(function (index, el) {
                    $(el).parent().removeClass('_open _current');
                });
            }
            $(this).parent().toggleClass('_open');
            $(this).parent().addClass('_current');
        });
    }
});

