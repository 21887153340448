import $ from 'jquery';
import Repeater from "./Repeater";
import DependedElement from "./DependedElement";

$(document).on('repeater-row:add', (event, row) => {
    row.$el.find('.repeater-field__row-header .row__num').text(`#${row.repeater.rows.length}`);

    row.repeater.rows.forEach((item) => {
        if(item === row) {
            return;
        }
        item.$el.find('.repeater-field__row-content').slideUp();
    })
});
const row_header_update = (row) => {


    const recipient_full_name = [
        row.$el.find('input[name$="][name]"]').val(),
        row.$el.find('input[name$="][lastname]"]').val()
    ].filter((e) => e).join(" ")

    let products_text = [];

    const input_product = row.$el.find('input[data-product-name]:checked');
    input_product.each(function() {
        let input_type = $(this).parents('.recipient-product').find('input[data-product-type]:checked');
        let types = input_type.length ? [] : ['Δεν επιλέχθηκε μορφή προϊόντος'];
        input_type.each(function() {
            types.push($(this).data('product-type'))
        })
        products_text.push(`${$(this).data('product-name')}(${types.join(", ")})`)
    })

    const not_checked_product = row.$el.find('input[data-product-name]:not(:checked)')
    not_checked_product.each(function() {
        let input_type_not = $(this).parents('.recipient-product').find('input[data-product-type]:checked');
        input_type_not.each(function() {
            if ($(this).data('product-type') === 'Έντυπη μορφή') {
                $(this).prop("checked", false);
            }
        })
    })

    row.$el.find('.repeater-field__row-header .row__title').text([recipient_full_name, products_text.join(", ")].filter((e) => e).join(": "))

    row.repeater.singleRepeaterCheck(row);
    row.repeater.bundleRepeaterCheck(row);

    $('#current_printed').html($('input[data-product-type="Έντυπη μορφή"]:checked').length);
}

$('#same_addresses_for_all').on('change', function(e) {

    if(!$(this).prop('checked')) {
        $('.recipients.repeater-field input[name$="[own_address]"]').prop('checked', true).attr('checked', 'checked').change()
        $('body .controlled_by_same_addresses_for_all').addClass('controlled_by_same_addresses_for_all--false');
    } else {
        $('.recipients.repeater-field input[name$="[own_address]"]').prop('checked', false).removeAttr('checked').change()
        $('body .controlled_by_same_addresses_for_all').removeClass('controlled_by_same_addresses_for_all--false');
    }
    $.ajax({
        method: 'post',
        url: '/index.php?route=account/order_subscriptions/updateSameAddress',
        data: {
            'same_address' : $(this).prop('checked'),
            'order_id' : $('#order_id_of_subscription').val() ,
        },
        success: (response) => {
        },
    });
});

const repeater_purge_limits = function(event, row) {
    const max = $(this).data('product-max');
    const product_id = $(this).data('product-id');

    // Single products
    if(row.repeater.$rows_cnt.find(`input[data-product-id="${product_id}"]:checked`).length > max) {
        row.repeater.bundleRepeaterCheck(row)
    } else {
        row.repeater.$rows_cnt.find(`input[data-product-id="${product_id}"]`).prop('disabled', false)
    }

    update_add_btn(event, row.repeater)
}
$(document).on('repeater-row:init', (event, row) => {
    // accordion
    row.$el.find('.repeater-field__row-header').click(function(){
        $(this).next().slideToggle();
    });
    // header change on type
    row.$el.find('input[name$="][name]"], input[name$="][lastname]"]').on('input', row_header_update.bind(null, row));
    row.$el.find('input[data-product-name], input[data-product-type]').on('change', row_header_update.bind(null, row));

    // init select2
    row.$el.find('.select-js').select2({
        minimumResultsForSearch: -1,
        dropdownAutoWidth: true,
        width: 'style',
    });

    row.$el.find('[data-depend]').each(function() {
        new DependedElement(row.$el, $(this).data('depend'), $(this));
    });

    /**
     * Handle limits on products
     * note that both click and change need to be registered since checkbox listens on both
     */
    row.$el.find('input[data-product-max]').on('change click', function(e) {
        repeater_purge_limits.call(this, e, row)
    });

    /**
     * Email input blur
     */
    row.$el.find('input[name$="[email]"]').on('blur', function() {
        $(this).removeClass('has-error');
        $(this).next('.js-has-error').remove();

        $.ajax({
            method: 'post',
            url: '/index.php?route=account/order_subscriptions/check_recipient_email',
            data: {
                email: $(this).val(),
                product_ids: row.$el.find(`input[data-product-id]:checked`).map(function(){return $(this).data('product-id')}).get(),
                order_id : $('#order_id_of_subscription').val(),
            },
            success: (response) => {
                if(response.error) {
                    $(this).addClass('has-error');
                    $(this).parent().append($(`<div class="js-has-error">${response.error}</div>`));
                }
            }
        })
    });
});
const update_add_btn = (event, repeater) => {
    if(!repeater.rows.length) {
        return;
    }
    const row = repeater.rows[0]
    const products_with_limits = row.$el.find('[data-product-max]')
    if(products_with_limits.length === row.$el.find('.recipient-product').length) {

        const total_allowed = products_with_limits
            .map(function() { return $(this).data('product-max')})
            .get()
            .reduce((ac, cv) => {
                return ac + (parseFloat(cv)||0);
            }, 0);

        const total_selected = repeater.$rows_cnt.find(`input[data-product-max]:checked`).length

        if(total_allowed === total_selected) {
            repeater.disableRowAdd()
        } else {
            repeater.enableRowAdd()
        }
    }
}
$(document).on('repeater:update', update_add_btn);
$('#form-recipients').on('submit', function(e) {
    e.preventDefault();
    let accToOpen = null;
    $('.account-form--field .js-has-error').remove();
    $('.repeater-field__row-content .js-has-error').remove();
    $.ajax({
        method: 'post',
        url: '/index.php?route=account/order_subscriptions/saveRecipients',
        data: $('#form-recipients').serialize(),
        beforeSend: function () {
            //$(element).button('loading');

            $( '.loading-overlay' ).show();
            $( '.loading-overlay-image-container' ).show();
        },
        success: (response) => {
            $( '.loading-overlay' ).hide();
            $( '.loading-overlay-image-container' ).hide();
               alert('Επεξεργαστήκατε επιτυχώς την λίστα με τους παραλήπτες σας!');
               window.location.reload();
        },
        error: function (xhr, ajaxOptions, thrownError) {
            $( '.loading-overlay' ).hide();
            $( '.loading-overlay-image-container' ).hide();

            let errors  = xhr.responseJSON.error;

            if (errors.empty_recipients){
                alert(errors.empty_recipients);
            }else{
                errors.forEach((error, _key) => {

                    if (error === null) {
                        return;
                    }

                    for (const [field, value] of Object.entries(error)) {
                        if (field === 'valid' && value){
                            continue;
                        }

                        if (field === 'products'){
                            let element = $('.row-content-' + _key + ' .repeater-field__row-content-inner .recipient-product:last');
                            if (accToOpen === null){
                                accToOpen = $('.row-content-' + _key);
                            }

                            element.after(`<div class="js-has-error">${value}</div>`);
                        }else{
                            let element = $('#recipient_'+ field + '_' + _key + '');
                            if (accToOpen === null){
                                accToOpen = element.closest('.repeater-field__row-content')
                            }
                            element.after(`<div class="js-has-error">${value}</div>`);
                        }


                    }
                });
            }


            if(accToOpen !== null){
                $('.repeater-field__row-content').hide()
                accToOpen.show();

                window.scrollTo({
                	top: accToOpen.offset().top - 100,
                	behavior: "smooth"
                });
            }
        }
    })
});

// Repeater fields
$('.repeater-field').each(function() {
    new Repeater($(this));
});