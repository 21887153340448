import {gsap} from "gsap";

const submenu_tm = function() {

    const tm = gsap.timeline({paused: true, reversed: true});

    tm.set($(this).find('.sub-menu__arrow'), {
        left: () => $(this).offset().left + $(this).outerWidth() / 2 - $(this).find('.sub-menu__arrow').width() / 2
    });

    tm.fromTo($(this).find('.sub-menu'), {
        display: 'none',
        opacity: 0,
        top: () => parseInt(getComputedStyle(document.body)
            .getPropertyValue('--header-height')) + 20
    }, {
        display: 'block',
        opacity: 1,
        top: () => parseInt(getComputedStyle(document.body)
            .getPropertyValue('--header-height'))
    });

    $(window).resize(() => {
        tm.invalidate();
    });

    return tm;
}

const submenu_tm_mobile = function() {

    const tm = gsap.timeline({paused: true, reversed: true});

    tm
        .to($(this).parent('.megamenu'), {
            left: '-100%'
        }, 0.3)
        .to($(this).parent('.megamenu').children('li'), {
            left: '-100%',
            duration: 1.3,
            stagger: 0.03
        }, 0)
        .to($(this).find('.sub-menu'), {
            left: 0,
            duration: .5,
        }, 0.2)
        .from($(this).find('.product, .sub-menu__back'), {
            left: '100%',
            duration: .7,
            stagger: .03,
        }, 0);

    return tm;
}
$(document).ready(() => {
    gsap.matchMedia().add("(max-width: 1023.99px)", () => {

        $('.megamenu .with-sub-menu').each(function() {
            this.timeline = submenu_tm_mobile.call(this);
        });

        $(document).on('click.menu_item_with_children_hover', '.megamenu .with-sub-menu .megamenu__chevron', function(e) {
            e.preventDefault();
            $.map($('.megamenu .with-sub-menu').not($(this).parents().eq(1)), function(el) {
                el.timeline.timeScale(3).reverse();
            });

            $(this).parents().eq(1).get(0).timeline.play();
        });

        $(document).on('click.menu_item_with_children_hover', '.megamenu .sub-menu__back', function() {
            $(this).parents('.with-sub-menu').eq(0)[0].timeline.reverse();
        });

        return () => {
            $('.megamenu .with-sub-menu').each(function() {
                this.timeline.kill();
            });

            $(document).off('click.menu_item_with_children_hover');
        }
    });

    gsap.matchMedia().add("(min-width: 1024px)", () => {

        $('.megamenu .with-sub-menu').each(function() {
            $(this).find('.sub-menu').prepend("<span class='sub-menu__arrow' />")
            this.timeline = submenu_tm.call(this);
            this.timeout = null;
        });

        $(document).on('mouseenter.menu_item_with_children_hover', '.megamenu .with-sub-menu', function() {

            $.map($('.megamenu .with-sub-menu').not($(this)), function(el) {
                el.timeline.timeScale(3).reverse();
            });

            clearTimeout(this.timeout);
            this.timeline.play();
        });

        $(document).on('mouseleave.menu_item_with_children_hover', '.megamenu .with-sub-menu', function() {
            this.timeout = setTimeout(this.timeline.reverse.bind(this.timeline), 500);
        });

        $(window).on('scroll.menu_item_with_children_hover', function() {
            $('.megamenu .with-sub-menu').each(function() {
                this.timeline.seek(0).pause();
            });
        });

        return () => {
            $('.megamenu .with-sub-menu').each(function() {
                $(this).find('.sub-menu__arrow').remove();
                this.timeline.kill();
            });

            $(document).off('mouseenter.menu_item_with_children_hover');
            $(document).off('mouseleave.menu_item_with_children_hover');
            $(window).off('scroll.menu_item_with_children_hover');
        }
    });
})
