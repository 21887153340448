import $ from "jquery";

let step_index = 0;

export default class CheckoutStep {

    /**
     *
     * @param step
     */
    constructor(step) {
        this.id = step_index;
        this.index = step.index;
        this.editable = step.editable;
        this.endpoint = step.endpoint;
        step_index++;
        this.$label = $(`<li${(step.complete ? ' data-complete' : '') + (this.editable ? ' data-editable' : '')} data-id="${this.id}">${step.label}</li>`);
        this.setStatus(step.complete);
    }

    /**
     * @fires CheckoutStep#checkout-popup:update
     */
    attachEvents() {
        const _this = this;

        const $selector = this.$content.prop("tagName") === 'FORM' ? this.$content : this.$content.find('form');

        $selector.submit(function(e) {
            e.preventDefault();

            let formData = $(this).serializeArray();
            formData.push({name: "step", value: _this.index});

            $.ajax({
                url: $(this).attr('action'),
                method: 'post',
                data: formData,
                complete: (jqXHR, textStatus) => {
                    $(document).trigger('checkout-step:submit', [_this, jqXHR, textStatus]);
                }
            })
        });
    }

    setStatus(status) {

        this.status = status;

        if(this.status) {
            this.$label.get(0).setAttribute('data-complete', '');
        }
    }

    /**
     *
     * @returns Promise
     */
    getContent() {

        const deferred = $.Deferred();

        const _this = this;

        $.ajax({
            url: this.endpoint,
            method: 'post',
            success: (data, textStatus, jqXHR) => {
                _this.$content = $(data);
                _this.attachEvents();
                deferred.resolve(_this, jqXHR, textStatus, data);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                deferred.reject(_this, jqXHR, textStatus, errorThrown);
            }
        });

        return deferred.promise();
    }
}