import './scripts';
import './menu';
// import './tabs';
// import './fancybox';
import './footer';
import './category';
import './product';
import './account_page';
import './search';
import './select2-customize';
import './information-page';
import './kTabs';