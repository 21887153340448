$(() => {
    (() => {
        const $select = $('.category-list__filters-header select').select2({
            placeholder: custom_lang.industries,
            minimumResultsForSearch: -1,
            width: 'style',
            dropdownAutoWidth: true,
            dropdownParent: $('.category-list__filters-header')
        });
        if (!$select.length) {
            return
        }
        $select.data('select2').$container.addClass('category-filters');
        $select.data('select2').$dropdown.addClass('category-filters');
    })();

    $('.js-select2').each(function () { // ! DO NOT DELETE | ACCOUNT PAGES REQUIREMENT
        $(this).select2({
            minimumResultsForSearch: -1,
            width: 'style',
        });
    });

    const $brainy_select = $('.bf-form select')
    const $brainy_clear = $('.bf-form .bf-buttonclear-box')

    $brainy_select.val() === "" ? $brainy_clear.hide() : $brainy_clear.show();
    $brainy_select.change(function() {
        $(this).val() === "" ? $brainy_clear.hide() : $brainy_clear.show();
    });
    $brainy_clear.find('input[type="reset"]').click(function() {
        $brainy_clear.hide();
        $brainy_select.val('').trigger('change');
    });
});